import {Box, Grid, Typography} from "@mui/material";
import VideoBackground from "../../shared/VideoBackground";
import './index.scss';
import {LangFrameList, LangFrameLogos, LangFrameSkillList, LangFrameSoftwareList} from "./contents";

export default function LanguagesAndFrameworks() {
  return (
    <Grid container sx={{minHeight: '100vh'}}>
      <Grid item xs={12} md={6} lg={7} sx={{minHeight: '100%', position: 'relative', alignItems: 'center', display: 'flex'}}>
        <VideoBackground videoItems={
          [{
            url: "/vid/website-background-2.mp4",
            type: "video/mp4",
          }, {
            url: "/vid/website-background-2.webm",
            type: "video/webm",
          }]
        } poster="/img/website-background-3.jpg" />
        <Box className='lang-section-cover'></Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2' color='white' sx={{textAlign: 'center'}}>Languages &amp; Framworks</Typography>
          </Grid>
          {LangFrameLogos.map((item, index) => (
            <Grid item key={index} xs={3} sx={{
              textAlign: 'center',
              margin: 'auto'
            }}>
              <img src={item.url} alt={item.name} className='lang-icon-img' />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={5} sx={{minHeight: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'secondary.main', p:2, color: 'white'}} >
        <Box sx={{width: '100%'}}>
          <Typography variant='h4'>Languages &amp; Frameworks</Typography>
          <ul style={{columns:2}}>
            {LangFrameList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <Typography variant='h4' sx={{marginTop: 5}}>Software</Typography>
          <ul style={{columns:1}}>
            {LangFrameSoftwareList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <Typography variant='h4' sx={{marginTop: 5}}>Skills</Typography>
          <ul style={{columns:1}}>
            {LangFrameSkillList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Box>
      </Grid>
    </Grid>
  )
}