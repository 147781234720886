export interface employment {
  company: string;
  jobTitle: string;
  companyBio: string;
  description: string[];
  start: string;
  end: string;
}

export const EmploymentHistoryItems: employment[] = [
  {
    company: "Carestream Dental Ltd",
    jobTitle: "Senior Software Developer",
    companyBio: "Carestream Dental is transforming dentistry, simplifying technology and changing lives around the world with its innovative digital product line of systems, solutions and support. From intraoral and extraoral imaging equipment to CAD/CAM solutions, imaging analysis software to practice management systems, Carestream Dental technology captures two billion images annually and aids in more precise diagnoses, improved workflows and superior patient care. ",
    description: [
      "Planning program increments that deliver new features and value to our customers",
      "Guiding the team in the creation of a solution approach and architectural plan for each new feature set",
      "Ensuring architectural and technical alignment with the group technology strategy and business drivers",
      "Owning release implementation plans and the technical delivery of product releases",
      "Owning and leading the design and implementation of new features",
      "Implementing complete user story and defect solutions as a “full stack” developer and active member of the scrum team.",
      "Providing consultancy to team members on the solution for user stories and defects",
      "Creating guidance and tools to aid the migration of features to the current technology stack",
      "Monitoring the performance of the team to ensure consistent adoption of Agile / Lean / Kanban principles and adherence to quality procedures and work instructions",
      "Participating in meetings as a technical consultant for business stakeholders"
    ],
    start: "Oct 2022",
    end: "Present Day"
  },
  {
    company: "Trinity Claims",
    jobTitle: "Software Engineer",
    companyBio: "Trinity Claims deliver straightforward and reliable claims solutions, specializing in household and motor insurance claims with a focus on customer service.",
    description: [
      "Provide input into the design of the system and processes to enhance business performance",
      "Actively participate in the development of the system in line with the team’s processes and procedures",
      "Rectify software defects in a timely fashion as and when identified",
      "Follow project plans and meet agreed delivery dates following SCRUM methodology",
      "Participate in group sessions with the development team and directly with business stakeholders",
      "Participate in the prioritization and estimation of system enhancement requests",
      "Work independently and as part of a team",
      "Undertake any individual or team task or activity reasonably requested and in line with the company values"
    ],
    start: "Jan 2022",
    end: "Sept 2022"
  },
  {
    company: "KHL Group LLP",
    jobTitle: "Web Developer",
    companyBio: "KHL Group is a diversified media company that delivers industry news and information in various formats for the construction and power markets. Using its strong market-leading magazine brands, KHL has expanded into digital magazines, newsletters, apps, directories, new media technologies, exhibitions, and conferences.",
    description: [
      "Design, develop and maintain custom web applications using Microsoft technologies used throughout the company",
      "Integration of third-party services for bespoke editorial and audience development software",
      "Perform maintenance on pre-existing ‘out-of-the-box’ systems such as WordPress and OpenCart",
      "Update legacy applications to modern technologies using .NET Core",
      "Maintaining web servers hosted in-house and in the cloud",
      "Managing a small development team"
    ],
    start: "March 2016",
    end: "Dec 2021"
  },
  {
    company: "Hastings Direct",
    jobTitle: "IT Service Desk - 1st Line Technician",
    companyBio: "Hastings Direct is a data and digitally-focused general insurance provider that offers a range of competitive and straightforward products and services for the automobile/transport and home market.",
    description: [
      "1st point of contact for any IT related issues/requests",
      "Managing jobs that come into the service desk",
      "Completing Active Directory tasks",
      "Managing profiles on the broker system used by the business",
      "Building new computers to suit user requirements"
    ],
    start: "August 2012",
    end: "Feb 2016"
  }
]