import { Document, Page, Text, View, StyleSheet, Font, Link, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import * as ProfileContent from "../sections/profile/contents";
import * as SkillsContent from "../sections/languages-and-frameworks/contents";
import * as EmploymentContent from "../sections/employment-history/contents";
import * as QualificationContent from "../sections/qualifications/contents";
import * as AboutContent from "../sections/about-me/contents";
import fileDownload from "js-file-download";

const Doc = () => {

  Font.register({
    family: "BigShouldersDisplay",
    fonts: [
      {
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Regular.ttf",
      },
      {
        fontWeight: 100,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-ExtraLight.ttf"
      },
      {
        fontWeight: 200,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Light.ttf"
      },
      {
        fontWeight: 300,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-light.ttf"
      },
      {
        fontWeight: 400,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Regular.ttf"
      },
      {
        fontWeight: 500,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Medium.ttf"
      },
      {
        fontWeight: 600,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-SemiBold.ttf"
      },
      {
        fontWeight: 700,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Bold.ttf"
      },
      {
        fontWeight: 800,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-ExtraBold.ttf"
      },
      {
        fontWeight: 900,
        src: "/fonts/BigShouldersDisplay/BigShouldersDisplay-Black.ttf"
      }
    ]
  });

  Font.register({
    family: "Epilogue",
    fonts: [
      {
        src: "/fonts/Epilogue/Epilogue-Regular.ttf",
      },
      {
        fontWeight: 100,
        src: "/fonts/Epilogue/Epilogue-Thin.ttf"
      },
      {
        fontWeight: 200,
        src: "/fonts/Epilogue/Epilogue-ExtraLight.ttf"
      },
      {
        fontWeight: 300,
        src: "/fonts/Epilogue/Epilogue-Light.ttf"
      },
      {
        fontWeight: 400,
        src: "/fonts/Epilogue/Epilogue-Regular.ttf"
      },
      {
        fontWeight: 500,
        src: "/fonts/Epilogue/Epilogue-Medium.ttf"
      },
      {
        fontWeight: 600,
        src: "/fonts/Epilogue/Epilogue-SemiBold.ttf"
      },
      {
        fontWeight: 700,
        src: "/fonts/Epilogue/Epilogue-Bold.ttf"
      },
      {
        fontWeight: 800,
        src: "/fonts/Epilogue/Epilogue-ExtraBold.ttf"
      },
      {
        fontWeight: 900,
        src: "/fonts/Epilogue/Epilogue-Black.ttf"
      },
      {
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-Italic.ttf"
      },
      {
        fontWeight: 100,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-ThinItalic.ttf"
      },
      {
        fontWeight: 200,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-ExtraLightItalic.ttf"
      },
      {
        fontWeight: 300,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-LightItalic.ttf"
      },
      {
        fontWeight: 400,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-RegularItalic.ttf"
      },
      {
        fontWeight: 500,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-MediumItalic.ttf"
      },
      {
        fontWeight: 600,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-SemiBoldItalic.ttf"
      },
      {
        fontWeight: 700,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-BoldItalic.ttf"
      },
      {
        fontWeight: 800,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-ExtraBoldItalic.ttf"
      },
      {
        fontWeight: 900,
        fontStyle: "italic",
        src: "/fonts/Epilogue/Epilogue-BlackItalic.ttf"
      }
    ]
  })

  const styles = StyleSheet.create({
    body: {
      fontFamily: "Epilogue",
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.4
    },
    header: {
      borderBottomStyle: "solid",
      borderBottomColor: "#025062",
      borderBottomWidth: "3px",
      height: 95
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row",
    },
    mainContent: {
      width: "60%",
      paddingHorizontal: 25,
      paddingVertical: 25
    },
    mainContentFullWidth: {
      width: "100%",
      paddingHorizontal: 25,
      paddingVertical: 25
    },
    sidebarContent: {
      width: "40%",
      paddingHorizontal: 25,
      paddingVertical: 25
    },
    bgPrimary: {
      backgroundColor: "#025062"
    },
    fgPrimary: {
      color: "#025062"
    },
    fgWhite: {
      color: "#ffffff"
    },
    headerText: {
      color: "#025062",
      fontFamily: "BigShouldersDisplay",
      fontWeight: 600
    },
    subHeaderText: {
      color: "#025062",
      fontFamily: "BigShouldersDisplay",
      fontWeight: 400,
      textAlign: "right"
    },
    listContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      paddingVertical: 5
    },
    compactListContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      paddingTop: 2
    },
    sidebarSection: {
      marginBottom: 30
    },
    pageNumber: {
      position: 'absolute',
      bottom: 25,
      left: 0,
      right: 25,
    },
  });

  const ListItem: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => (
    <View style={styles.listContainer}>
      <Text>{'\u2022'}</Text>
      <Text>{props.children}</Text>
    </View>
  )

  const CompactListItem: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => (
    <View style={styles.compactListContainer}>
      <Text>{'\u2022'}</Text>
      <Text>{props.children}</Text>
    </View>
  )

  const firstEmploymentItem = [...EmploymentContent.EmploymentHistoryItems][0];
  const remainingEmploymentItems = [...EmploymentContent.EmploymentHistoryItems].slice(1);

  return (
    <Document>
      <Page size={"A4"} style={styles.body}>
        <View style={[styles.header, styles.contentContainer, {alignItems: "center"}]} fixed>
          <View style={styles.mainContent}>
            <View style={{display: "flex", flexDirection: "row", alignItems: "center", gap: 10}}>
              <View>
                <Image src={"/img/profileimg.jpg"} style={{width: 50, height: 50, borderRadius: "100%"}}/>
              </View>
              <View>
                <Text style={[styles.headerText, {fontSize: 20}]}>{ProfileContent.ProfileTitle}</Text>
                <Text style={[styles.subHeaderText, {fontSize: 16}]}>{ProfileContent.ProfileSubTitle}</Text>
              </View>
            </View>
          </View>
          <View style={styles.sidebarContent}>
            <Text>Website: <Link style={styles.fgPrimary}
                                 src={"https://anthonymedhurst.co.uk"}>anthonymedhurst.co.uk</Link></Text>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.mainContent}>
            <View style={{marginBottom: 30}}>
              <Text style={[styles.headerText, {fontSize: 16}]}>Profile</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}}>
                <Text>{ProfileContent.ProfileBlurb}</Text>
              </View>
            </View>
            <View style={{marginBottom: 30}}>
              <Text style={[styles.headerText, {fontSize: 16}]}>Employment History</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}} wrap={false}>
                <Text
                  style={styles.fgPrimary}>{`${firstEmploymentItem.company}, ${firstEmploymentItem.jobTitle}`}</Text>
                <Text
                  style={[styles.fgPrimary, {fontStyle: "italic"}]}>{`${firstEmploymentItem.start} - ${firstEmploymentItem.end}`}</Text>
                <Text>{firstEmploymentItem.companyBio}</Text>
                <Text style={{paddingTop: 10}}>Job Description:</Text>
                <View>
                  {firstEmploymentItem.description.map((subItem, subIndex) => (
                    <CompactListItem key={`emplhistdesc_${0}_${subIndex}`}>{subItem}</CompactListItem>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.sidebarContent, styles.bgPrimary, styles.fgWhite, {height: 746.89}]}>
            <View style={styles.sidebarSection}>
              <Text style={[styles.headerText, styles.fgWhite, {fontSize: 16}]}>Skills</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}}>
                {SkillsContent.LangFrameSkillList.map((item, index) => (
                  <ListItem key={`skill_${index}`}>{item}</ListItem>
                ))}
              </View>
            </View>
            <View style={styles.sidebarSection}>
              <Text style={[styles.headerText, styles.fgWhite, {fontSize: 16}]}>Languages and Frameworks</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}}>
                <View style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
                  {SkillsContent.LangFrameList.map((item, index) => (
                    <View style={{width: "50%", paddingRight: 5}} key={`{lang_${index}`}>
                      <ListItem>{item}</ListItem>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.sidebarSection}>
              <Text style={[styles.headerText, styles.fgWhite, {fontSize: 16}]}>Software</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}}>
                {SkillsContent.LangFrameSoftwareList.map((item, index) => (
                  <ListItem key={`soft_${index}`}>{item}</ListItem>
                ))}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.mainContentFullWidth}>
            <View>
              <Text style={[styles.headerText, {fontSize: 16, paddingTop: 0}]} fixed>Employment History -
                Continued</Text>
              {remainingEmploymentItems.map((item, index) => (
                <View style={{paddingTop: 5, paddingBottom: 25}} key={`emplhist_${index}`} wrap={false}>
                  <Text style={styles.fgPrimary}>{`${item.company}, ${item.jobTitle}`}</Text>
                  <Text style={[styles.fgPrimary, {fontStyle: "italic"}]}>{`${item.start} - ${item.end}`}</Text>
                  <Text>{item.companyBio}</Text>
                  <Text style={{paddingTop: 10}}>Job Description:</Text>
                  <View>
                    {item.description.map((subItem, subIndex) => (
                      <CompactListItem key={`emplhistdesc_${index}_${subIndex}`}>{subItem}</CompactListItem>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.mainContentFullWidth}>
            <View style={{marginBottom: 30}}>
              <Text style={[styles.headerText, {fontSize: 16, paddingTop: 0}]} fixed>Education & Qualifications</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}} wrap={false}>
                <View style={{display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "1px solid #025062"}}>
                  <Text style={{fontWeight: 700, width: "40%", paddingTop: 5, paddingHorizontal: 5}}>Subject</Text>
                  <Text style={{fontWeight: 700, width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>Date Studied</Text>
                  <Text style={{fontWeight: 700, width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>Qualification Type</Text>
                  <Text style={{fontWeight: 700, width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>Grade</Text>
                </View>
                {QualificationContent.Qualifications.map((item, index) => {
                  const bgColor = index % 2 == 0 ? "rgba(2,80,98,0.1)" : undefined;
                  return (
                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: bgColor}} key={`qual_${index}`}>
                      <Text style={{fontWeight: 700, width: "40%", paddingTop: 5, paddingHorizontal: 5}} >{item.subject}</Text>
                      <Text style={{width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>{item.dateStudied}</Text>
                      <Text style={{width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>{item.qualificationType}</Text>
                      <Text style={{width: "20%", textAlign: "center", paddingTop: 5, paddingHorizontal: 5}}>{item.grade}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View>
              <Text style={[styles.headerText, {fontSize: 16}]}>Hobbies and Interests</Text>
              <View style={{paddingVertical: 5, paddingRight: 10}}>
                <Text>{AboutContent.AboutMeBlurb}</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => {
          const color = pageNumber == 1 ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.5)";
          return (
            <Text style={{color: color, fontSize: 8, textAlign: "right", fontFamily: "Epilogue"}}>{`Page ${pageNumber} of ${totalPages}`}</Text>
          )
        }} fixed />
      </Page>
    </Document>
  )
};

export const DocPreview = () => {
  return (
    <div style={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <div style={{width: "90vw", height: "90vh"}}>
        <PDFViewer width={"100%"} height={"100%"}>
          <Doc/>
        </PDFViewer>
      </div>
    </div>
  )
};

export const DownloadDoc = async () => {
  const blob = await pdf(Doc()).toBlob();
  fileDownload(blob, "Anthony Medhurst - CV.pdf");
}