interface ILangFrameLogos {
  url: string,
  name: string,
}

export const LangFrameLogos: ILangFrameLogos[] = [
  {url: '/img/logos/HTML5-logo-min.png', name: 'HTML5'},
  {url: '/img/logos/javascript-logo-min.png', name: 'Javascript'},
  {url: '/img/logos/css3-logo-min.png', name: 'CSS3'},
  {url: '/img/logos/jquery-logo-min.png', name: 'JQuery'},
  {url: '/img/logos/jquery-ui-logo-min.png', name: 'JQuery UI'},
  {url: '/img/logos/typescript-logo-min.png', name: 'Typescript'},
  {url: '/img/logos/Angular-logo-min.png', name: 'Angular'},
  {url: '/img/logos/react-logo-min.png', name: 'React'},
  {url: '/img/logos/bootstrap-logo-min.png', name: 'Bootstrap'},
  {url: '/img/logos/material-ui-logo-min.png', name: 'Material UI'},
  {url: '/img/logos/c-sharp-logo-min.png', name: 'C#'},
  {url: '/img/logos/asp-net-logo-min.png', name: 'ASP.NET'},
  {url: '/img/logos/NET-CORE-Logo-min.png', name: '.NET Core'},
  {url: '/img/logos/Blazor-logo-min.png', name: 'Blazor'},
  {url: '/img/logos/Visual-Studio-Logo-min.png', name: 'Visual Studio'},
  {url: '/img/logos/mssql-logo-min.png', name: 'Microsoft SQL Server'},
  {url: '/img/logos/azure-logo-min.png', name: 'Azure'},
  {url: '/img/logos/MySQL-logo-min.png', name: 'MySQL'},
  {url: '/img/logos/PHP-logo-min.png', name: 'PHP'},
]

export const LangFrameList: string[] = [
  "HTML",
  "CSS",
  "JavaScript",
  "C#",
  ".NET Core",
  "ASP.NET",
  "SQL",
  "MVC",
  "JQuery",
  "JQuery UI",
  "Typescript",
  "Angular",
  "React",
  "Bootstrap",
  "Material UI",
  "Blazor",
  "Identity Framework",
  "Entity Framework",
  "API’s",
  "XAML",
  "PHP",
]

export const LangFrameSoftwareList: string[] = [
  "Cloud services - Azure and DevOps",
  "Microsoft Visual Studio",
  "Microsoft Office Products, including Visio",
  "Microsoft SQL Management Studio",
  "Windows Desktop, Mobile, and Server Operating systems"
]

export const LangFrameSkillList: string[] = [
  "Performance optimizations",
  "Troubleshooting and solutions development",
  "Analytical thinking",
  "Software design and development",
  "Coding and Scripting"
]