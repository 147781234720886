import {employment} from "../contents";
import {Box, Button, Typography} from "@mui/material";
import {useState} from "react";

interface Props {
  item: employment
}

export default function HistoryItem({item}: Props){
  const [isToggled, setIsToggle] = useState(false);

  const handleToggle = () => {
    setIsToggle(!isToggled);
  }

  return (
    <Box className="steps-container">
      <Box className="content">
        <Typography variant='h4'>{item.company}</Typography>
        <Typography variant='h5'>{item.jobTitle}</Typography>
        <hr/>
        <Typography>{item.companyBio}</Typography>
        <Box sx={{marginTop: 2}}>
          <Button variant='contained'
                  color='primary'
                  onClick={() => handleToggle()}
          >Show Job Description</Button>
        </Box>
        {isToggled && (
          <Box className="collapse show" id="collapseTrinity" sx={{marginTop: 3}}>
            <Typography>Job Description:
            </Typography>
            <ul>
              {item.description.map((subitem, index) => (
                <li key={index}>{subitem}</li>
              ))}
            </ul>
          </Box>
        )}

      </Box>
      <i className="step-line"></i>
      <Box className="date">{item.end.replace(' ', '\n')}</Box>
      <Box className="date date-end">{item.start.replace(' ', '\n')}</Box>
    </Box>
  )
}