const webYearsExperience = () => {
  const currentDate = new Date();
  const startDate = new Date(2016, 3, 1);
  return currentDate.getFullYear() - startDate.getFullYear();
}
const itYearsExperience = () => {
  const currentDate = new Date();
  const startDate = new Date(2012, 8, 1);
  return currentDate.getFullYear() - startDate.getFullYear();
}

export const ProfileTitle = "Anthony Medhurst";

export const ProfileSubTitle = "Full Stack .NET Web Developer"

export const ProfileBlurb = `Experienced and driven web developer with ${webYearsExperience()}+ years’ worth of experience in various web application technologies, integrating third party services and ${itYearsExperience()}+ years’ working in the I.T. industry.`;


