import VideoBackground from "../../shared/VideoBackground";
import {Box, Grid, Typography} from "@mui/material";
import './index.scss';
import { ProfileBlurb, ProfileSubTitle, ProfileTitle } from "./contents";

export default function Profile() {
  return (
    <>
      <VideoBackground videoItems={
        [{
          url: "/vid/website-main-bg.mp4",
          type: "video/mp4",
        }, {
          url: "/vid/website-main-bg.webm",
          type: "video/webm",
        }]
      } poster="/img/website-main-bg-poster.jpg"/>
      <Box className='profile-section-cover'></Box>
      <Grid container sx={{
        minHeight: 'calc(100vh - 48px)',
        height: '100%',
        maxWidth: 1200,
        '@media (min-width: 900px)': {
          alignItems: 'center'
        }
      }}>
        <Grid item xs={12} md={6} xl={5} sx={{
          textAlign: 'center',
          marginLeft: 'auto',
          padding: 1,
          '@media (min-width: 900px)': {
            textAlign: 'right'
          }
        }}>
          <Typography variant='h1' color='white' sx={{
            fontSize: '3.5rem',
            '@media (min-width: 600px)': {
              fontSize: '6rem'
            }
          }}>{ProfileTitle}</Typography>
          <Typography variant='h3' color='white' sx={{
            marginBottom: 3,
            fontSize: '2.3rem',
            '@media (min-width: 600px)': {
              fontSize: '3rem'
            }
          }}>{ProfileSubTitle}</Typography>
          <Typography color='white'>
            {ProfileBlurb}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}