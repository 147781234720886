import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem, ListItemButton,
  ListItemText, Toolbar, Tooltip,
  Typography
} from "@mui/material";
import {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.scss';
import {Link} from "react-router-dom";
import { Download } from "@mui/icons-material";
import { DownloadDoc } from "../pdf/pdf-doc";

const drawerWidth = 240;

interface Props {
    navItems: {
        name: string,
        cssClass: string,
        clickFunc: () => void,
        url: string
    }[];
}

export default function Navbar({navItems}: Props) {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{
            textAlign: 'center',
            backgroundColor: 'secondary.main',
            color: '#fff',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}
        >
            <Typography variant="h4" sx={{my: 2}}>
                Anthony Medhurst
            </Typography>
            <Divider sx={{borderColor: 'rgba(255,255,255,0.65)'}}/>
            <List>
                {navItems.map((navItem, index) => (
                    <ListItem key={index} disablePadding className={navItem.cssClass}>
                        <ListItemButton sx={{textAlign: 'center'}} component={Link} to={navItem.url}>
                            <ListItemText primary={navItem.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Typography variant='caption' sx={{marginTop: 'auto', marginBottom: 3}}>
                &copy;{new Date().getFullYear()} Anthony Medhurst
            </Typography>
        </Box>
    );

    return (
        <>
            <AppBar component="nav" color='secondary'>
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            mr: 2, display: {sm: 'none'},
                            border: '1px solid #fff',
                            opacity: 0.65,
                            "&hover": {
                                opacity: 1
                            }
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box sx={{display: {xs: 'none', sm: 'block', marginLeft: 'auto'}}} className="App-navigation">
                        {navItems.map((navItem, index) => (
                            <Button key={index} sx={{color: '#fff'}}
                                    className={navItem.cssClass}
                                    onClick={navItem.clickFunc}>
                                {navItem.name}
                            </Button>
                        ))}
                      <Tooltip title={"Download printable portfolio"}>
                        <IconButton onClick={() => DownloadDoc()} sx={{color: '#fff'}} className={"App-navigation-item"}>
                          <Download />
                        </IconButton>
                      </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}
                    }}
                    className="App-navigation"
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    )
}