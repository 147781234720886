import React, {useEffect, useRef} from 'react';
import './Main.scss';
import {
    Box,
    Toolbar
} from "@mui/material";
import Navbar from "./shared/Navbar";
import useScrollSpy from 'react-use-scrollspy';
import Profile from "./sections/profile";
import LanguagesAndFrameworks from "./sections/languages-and-frameworks";
import EmploymentHistory from "./sections/employment-history";
import AboutMe from "./sections/about-me";

interface Props {
    navIndex?: number;
}

export default function Main({navIndex}: Props) {  
    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];
    
    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -300,
    });
    
    const navItems: {name: string, url: string}[] = [
        {name: "Home", url: "/"},
        {name: "Languages & Frameworks", url: "/languages-and-frameworks"},
        {name: "Employment History", url: "/employment-history"},
        {name: "About Me", url: "/about-me"}
    ];

    useEffect(() => {
        if(navIndex && navIndex !== 0){
            // @ts-ignore
            sectionRefs[navIndex].current.scrollIntoView({block: "start", behavior: "auto"})
        } else if (navIndex === 0){
            window.scrollTo(0,0);
        }
    },[navIndex]);

    return (
        
            <Box sx={{display: 'flex'}}>
                <Navbar
                    navItems={navItems.map((navItem, index) => {
                        return {
                            name: navItem.name,
                            cssClass: activeSection === index ? "App-navigation-item App-navigation-item--active" : "App-navigation-item",
                            clickFunc: () =>
                            // @ts-ignore
                            sectionRefs[index].current.scrollIntoView({block: "start", behavior: "smooth"}),
                            url: navItem.url
                        };
                    })}
                />
                <Box component="main" sx={{width: '100vw'}}>
                    <Toolbar variant='dense'/>
                    <Box className="App-section" ref={sectionRefs[0]} ><Profile/></Box>
                    <Box className="App-section" ref={sectionRefs[1]} ><LanguagesAndFrameworks /></Box>
                    <Box className="App-section" ref={sectionRefs[2]} ><EmploymentHistory /></Box>
                    <Box className="App-section" ref={sectionRefs[3]} ><AboutMe /></Box>
                </Box>
            </Box>
    );
}
