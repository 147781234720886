import {Box, Button, Grid, Typography} from "@mui/material";
import VideoBackground from "../../shared/VideoBackground";
import React from "react";
import { AboutMeBlurb } from "./contents";
import { Download, LinkedIn } from "@mui/icons-material";
import { DownloadDoc } from "../../pdf/pdf-doc";

export default function AboutMe() {
    return (
        <Box sx={{minHeight: '100vh', height: '100%'}}>
            <VideoBackground videoItems={
                [{
                    url: "/vid/website-background-3.mp4",
                    type: "video/mp4",
                }, {
                    url: "/vid/website-background-3.webm",
                    type: "video/webm",
                }]
            } poster="/img/website-background-3.jpg"/>

            <Grid container
                  sx={{backgroundColor: 'rgba(0,0,0,0.25)', minHeight: '100vh', height: '100%', alignItems: 'center'}}>
                <Grid item xs={12} sx={{
                    color: 'white', textAlign: 'center',
                    paddingLeft: 2, paddingRight: 2, paddingTop: 5, paddingBottom: 5
                }}>
                    <Typography variant='h2' sx={{marginBottom: 5}}>About Me</Typography>
                    <img src='/img/profileimg.jpg' style={{
                        width: 200,
                        borderRadius: '100%',
                        border: 1,
                        marginBottom: 3
                    }} alt='Anthony Medhurst'/>
                    <Typography sx={{maxWidth: 500, margin: 'auto', marginBottom: 3}}>{AboutMeBlurb}</Typography>
                    <div>
                    <Button
                      sx={{marginBottom: 3, textShadow: "initial !important"}}
                      startIcon={<LinkedIn />}
                      variant='contained'
                      color='primary'
                      size='large'
                      component='a'
                      href='https://www.linkedin.com/in/anthonymedhurst'
                      target='_blank'
                    >Visit me on LinkedIn</Button>
                    </div>
                    <div>
                    <Button
                      sx={{textShadow: "initial !important"}}
                      startIcon={<Download />}
                      variant='contained'
                      color='primary'
                      size='large'
                      onClick={() => DownloadDoc()}
                    >Download printable portfolio</Button>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}