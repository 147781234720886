import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {SiteTheme} from "./shared/SiteTheme";
import React from "react";
import Main from "./Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { DocPreview } from "./pdf/pdf-doc";

export default function App(){
    const theme = createTheme(SiteTheme());
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Main navIndex={0} />} />
                    <Route path='/languages-and-frameworks' element={<Main navIndex={1} />} />
                    <Route path='/employment-history' element={<Main navIndex={2} />} />
                    <Route path='/about-me' element={<Main navIndex={3} />} />
                    <Route path='/preview' element={<DocPreview />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}