import {Box} from "@mui/material";
import './VideoBackground.scss';

interface Props{
    videoItems:{
       url: string;
       type: string;
    }[];
    poster?: string;
}

export default function VideoBackground({videoItems, poster}: Props){
    return (
        <Box className="video-bg">
            <video autoPlay muted loop poster={poster}>
                {videoItems.map((item, index) => (
                    <source key={index} src={item.url} type={item.type} />
                ))}
            </video>
        </Box>
        
    )
}