import {EmploymentHistoryItems} from "./contents";
import {Box, Grid, Typography} from "@mui/material";
import './index.scss'
import HistoryItem from "./components/history-item";

export default function EmploymentHistory(){

  return (
    <Grid container sx={{
      background: 'linear-gradient(-90deg, #013A40 0%, #049dbf 100%)',
      color: 'white',
      paddingLeft: 2, paddingRight: 2, paddingTop: 5, paddingBottom: 5}} >
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant='h2'>Employement History</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className="timeline">
          <Box className="steps">
            {EmploymentHistoryItems.map((item, index) => (
              <HistoryItem item={item} key={index} />
            ))}

          </Box>
        </Box>
      </Grid>
    </Grid>

  )
}