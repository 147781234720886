import {ThemeOptions} from "@mui/material";


export const SiteTheme = () : ThemeOptions => {
    return {
        palette: {
            mode: 'light',
            primary: {
                main: '#049dbf',
            },
            secondary: {
                main: '#025062',
            },
            warning: {
                main: '#ff9800',
            },
        },
        typography: {
            h1: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
            fontFamily: 'Epilogue, san-serif',
            h2: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
            h3: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
            h4: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
            h5: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
            h6: {
                fontFamily: '"Big Shoulders Display",cursive',
            },
        },
    }
}