export interface qualification {
  subject: string;
  dateStudied: string;
  qualificationType: string;
  grade: string;
}

export const Qualifications : qualification[] = [
  {subject: "ITIL Foundation Certificate in IT Service Management", dateStudied: "July 13", qualificationType: "N/A", grade: "PASS"},
  {subject: "Public Service (Uniformed)", dateStudied: "Sept 04 – June 06", qualificationType: "BTEC National Certificate Level 3", grade: "Double PASS"},
  {subject: "D&T: Graphic Products", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "C"},
  {subject: "English", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "D"},
  {subject: "English Literature", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "C"},
  {subject: "I.C.T", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "C"},
  {subject: "Information Technology", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "Level 2"},
  {subject: "Mathematics", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "B"},
  {subject: "Physical Education", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "B"},
  {subject: "Science: Double Award", dateStudied: "Sept 02 – June 04", qualificationType: "G.C.S.E", grade: "D/D"}
]